import React, {useState} from "react";
import {render} from 'preact';
import DatePicker from "react-datepicker";
import {useEffect} from "preact/compat";

import('react-datepicker/dist/react-datepicker.min.css');

export const MultiDatePicker = ({targetInput, minDate, maxDate}) => {
    const defaultValue = targetInput.value ? targetInput.value.split(',').map(it => new Date(it)) : [];
    const [selectedDates, setSelectedDates] = useState(defaultValue);

    const onChange = (_dates) => {
        const dates = _dates.map((it) => new Date(it));
        setSelectedDates(dates);
    };

    useEffect(() => {
        const _dates = selectedDates.map(it => it.toISOString());
        targetInput.setAttribute('value', _dates);
    });

    return (
        <DatePicker
            selectedDates={selectedDates}
            inline
            selectsMultiple
            onChange={onChange}
            shouldCloseOnSelect={false}
            disabledKeyboardNavigation
            locale="hu-HU"
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
        />
    );
};

window.$.fn.multiDatePicker = function (props) {
    for (const it of this) {
        const current = $(it);
        if (!current.data('init')) {
            const root = document.createElement('div');
            current.after(root);
            render(<MultiDatePicker targetInput={it} {...props}/>, root);
            current.data('init', true);
        }
    }
};
export default MultiDatePicker;
window.component = {...window.component, MultiDatePicker};
