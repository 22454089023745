import React from "preact/compat";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from "react-datepicker";
import {useEffect, useState} from "preact/compat";

import {hu} from 'date-fns/locale';
import {render} from "preact";

registerLocale("hu-HU", hu);

function getIsoDate(date) {
    const isoDate = date.toISOString().split('T')[0];
    return isoDate;
}

const BDateTimePicker = ({id, name, minTime, maxTime, ...props}) => {
    const initialValue = props.initial ? new Date(props.initial) : null;
    const [selectedDate, setSelectedDate] = useState(initialValue);
    useEffect(() => {
    }, []);

    let minDateTime = null;
    let maxDateTime = null;
    if (minTime || maxTime) {
        const [minHour, minMinute] = minTime.split(':');
        const [maxHour, maxMinute] = maxTime.split(':');
        minDateTime = new Date(null, null, null, minHour, minMinute);
        maxDateTime = new Date(null, null, null, maxHour, maxMinute);
    }

    return (
        <>
            <input type="hidden"
                   name={name}
                   value={selectedDate ? getIsoDate(selectedDate) : ''}
                   id={id}
                   {...props}
            />
            <DatePicker
                selected={selectedDate}
                onChange={setSelectedDate}
                dateFormat="P"
                minTime={minDateTime}
                maxTime={maxDateTime}
                {...props}
            />
        </>
    );
};


window.$.fn.datetimePicker = function () {
    for (const it of this) {
        const current = $(it);
        if (!current.data('init')) {
            const dataId = current.data('propsId');
            const initialValue = current.data('initial');
            const {
                class: className,
                ...data
            } = JSON.parse(document.getElementById(dataId).textContent);
            const name = current.data('name');

            const root = document.createElement('div');
            current.after(root);
            render(<BDateTimePicker
                name={name}
                className={className}
                initial={initialValue}
                locale='hu-HU'
                {...data}
            />, root);
            current.data('init', true);
        }
    }
};

// export default DateRangePicker;
// window.component = {...window.component, BDatePicker: BDatePicker};
